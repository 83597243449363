import AuthService from '../../auth/auth-service';

export default {
  name: 'logout',
  async mounted() {
    if (!this.$route.query.loggedOut) {
      // federated = true forces a full logout from Auth0
      AuthService.logout({ returnTo: `${window.location.href}?loggedOut=true`, federated: true });
    } else {
      window.location.href = window.location.origin + '/';
    }
  }
};
